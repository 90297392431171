import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'
import Link from '../utils/link'
import ProjectsScroll from '../components/projects-scroll'
import ProjectsGrid from '../components/projects-grid'
import { Reel, Grid } from '../components/icons'
import { splitText } from "../utils/split-text"

class FilmsPage extends Component {
  state = {
    view: "Reel",
  }

  toggleView = () => {
    let { view } = this.state
    if (view === "Reel") {
      view = "Grid"
    } else {
      view = "Reel"
    }
    this.setState({ view })
  }

  setView = () => {
    const params = new URLSearchParams(window.location.search)
    const view = params.get('view')
    if (view) {
      this.setState({ view })
    }
  }

  componentDidMount() {
    this.setView()
  }

  render() {
    const { page, posts } = this.props.data
    let { view } = this.state

    return (
      <>
        <Seo
          path={page.slug === "films" ? "/" : `/${page.slug}/`}
          title={he.decode(page.seo.title)}
          description={page.seo.metaDesc}
          keywords={page.seo.metaKeywords}
          schema={page.seo.schema.raw}
          bodyClass={page.slug}
        />
        <div className='projects__view'>
          <button
            type="button"
            className={`projects__view-btn${view === 'Reel' ? ' active' : ''}`}
            onClick={() => this.setState({ view: "Reel" })}
          >
            <span>{splitText('Reel')}</span>
          </button>
          <button
            type="button"
            className={`projects__view-btn${view === 'Grid' ? ' active' : ''}`}
            onClick={() => this.setState({ view: "Grid" })}
          >
            <span>{splitText('Grid')}</span>
          </button>
        </div>
        {view === "Reel" && <ProjectsScroll posts={posts.nodes} />}
        {view === "Grid" && <ProjectsGrid posts={posts.nodes} />}
        <section className="footer">
          <div className="footer__inner">
            <ul>
              <li>
                <Link to="/about/">{splitText("About")}</Link>
              </li>
              <li>
                <Link to="/films">{splitText("Films")}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/photos/">{splitText("Photos")}</Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/xmedia/">
                  {splitText("Instagram")}
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </>
    )
  }
}

export const filmsQuery = graphql`
  query {
    posts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Films" } } } }
      }
    ) {
      nodes {
        title
        slug
        link
        acf {
          video
          tag
          gallery {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 1200
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
    page: wpPage(slug: { eq: "films" }) {
      title
      slug
      seo {
        metaDesc
        metaKeywords
        title
        schema {
          raw
        }
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default FilmsPage